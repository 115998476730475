import(/* webpackMode: "eager", webpackExports: ["ReactQueryProvider"] */ "/vercel/path0/apps/dashboard/app/react-query-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["ThemeProvider"] */ "/vercel/path0/apps/dashboard/app/theme-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CommandMenu"] */ "/vercel/path0/apps/dashboard/components/dashboard/command-menu.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Toaster"] */ "/vercel/path0/apps/dashboard/components/ui/toaster.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["TooltipProvider"] */ "/vercel/path0/apps/dashboard/components/ui/tooltip.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PostHogPageview","PHProvider"] */ "/vercel/path0/apps/dashboard/providers/PostHogProvider.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/apps/dashboard/styles/tailwind/tailwind.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/internal/ui/colors.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/.pnpm/geist@1.3.1_next@14.2.25/node_modules/geist/dist/mono.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-mono/GeistMono-Variable.woff2\",\"variable\":\"--font-geist-mono\",\"adjustFontFallback\":false,\"fallback\":[\"ui-monospace\",\"SFMono-Regular\",\"Roboto Mono\",\"Menlo\",\"Monaco\",\"Liberation Mono\",\"DejaVu Sans Mono\",\"Courier New\",\"monospace\"],\"weight\":\"100 900\"}],\"variableName\":\"GeistMono\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/.pnpm/next@14.2.25_@babel+core@7.26.10_react-dom@18.3.1_react@18.3.1/node_modules/next/font/local/target.css?{\"path\":\"../../node_modules/.pnpm/geist@1.3.1_next@14.2.25/node_modules/geist/dist/sans.js\",\"import\":\"\",\"arguments\":[{\"src\":\"./fonts/geist-sans/Geist-Variable.woff2\",\"variable\":\"--font-geist-sans\",\"weight\":\"100 900\"}],\"variableName\":\"GeistSans\"}");
